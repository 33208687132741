import axios from "@/plugins/axios";
export default {
  methods: {
    async GetPowerBiReportId(reportId) {
      await axios
        .post(
          "api/PowerBi/GetPowerBiReportId",
          {
            reportId: reportId,
          },
          { headers: { Authorization: `Bearer ${this.token}` } }
        )
        .then((res) => {
          console.log(res.data);
          this.reportDetails.token = res.data.token;
          this.reportDetails.embedUrl = res.data.embedUrl;
          this.reportDetails.reportId = res.data.reportId;
          this.reportDetails.workspaceId = res.data.workspaceId;
        });
    },
  },
};
